import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import { decryptFuc } from "../../utils/decrpt";

const getToken = () => localStorage.getItem("my_leads");
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const getAllClientsdata = createAsyncThunk(
  "clients/getAllClientsdata",
  async () => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(`/my_Leads/clients`, getAuthHeaders());
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const createClientsdata = createAsyncThunk(
  "clients/createClientsdata",
  async (data) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.post(
        `/my_Leads/clients`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const getSingleClientdata = createAsyncThunk(
  "clients/getSingleClientdata",
  async (id) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `/my_Leads/clients/${id}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const UpdateClientdata = createAsyncThunk(
  "clients/UpdateClientdata",
  async (data) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.patch(
        `/my_Leads/clients`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clientData: [],
    singleClientData: {},
    permission: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllClientsdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllClientsdata.fulfilled, (state, action) => {
        state.loading = false;
        state.clientData = action.payload;
      })
      .addCase(getAllClientsdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleClientdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleClientdata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleClientData = action.payload;
      })
      .addCase(getSingleClientdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const getClientsData = (state) => state.client.clientData;
export const getUniqueClientData = (state) => state.client.singleClientData;
export const clienterror = (state) => state.client.loading;
export default clientSlice.reducer;
