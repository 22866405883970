import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import SideBarComponent from "./components/SideBarComponent";
import TopBarComponent from "./components/TopBarComponent";
import { Suspense, lazy } from "react";
// import { UseAuth } from "./customhooks/UseAuth";
import HeaderComponent from "./components/header/HeaderComponent";
import FooterComponent from "./components/footer/FooterComponent";
import AuthGuard from "./authGuard/AuthGuard";
import ErrorPage from "./components/errorPage/ErrorPage";




const HomeComponent = lazy(() => import("./components/home/HomeComponent")
);
const AboutComponent = lazy(() => import("./components/about/AboutComponent")
);
const FeaturesComponent = lazy(() =>
  import("./components/features/FeaturesComponent")
);

const PaymentSuccessMessageComponent = lazy(() =>
  import("./components/paymentSuccessMessage/PaymentSuccessMessage")
);
const PricingComponent = lazy(() =>
  import("./components/pricing/PricingComponent")
);
const ContactComponent = lazy(() =>
  import("./components/contact/ContactComponent")
);
const FaqComponent = lazy(() =>
  import("./components/faq/FaqComponent")
);
const AppGalleryComponent = lazy(() =>
  import("./components/appgallery/AppGalleryComponent")
);
const PrivacyComponent = lazy(() =>
  import("./components/privacy/PrivacyComponent")
);
const TncComponet = lazy(() =>
  import("./components/termsandconditions/TncComponet")
);
const CoreDashboardComponent = lazy(() =>
  import("./components/coreDashboard/CoreDashboard")
);
const SubscriptionPageComponent = lazy(() =>
  import("./components/subscription/SubscriptionPage")
);
const LoginComponent = lazy(() => import("./components/login/LoginComponent"));
const RegisterComponent = lazy(() => import("./components/register/Register"));
const DashBoardComponent = lazy(() =>
  import("./components/dashboard/DashBoardComponent")
);
//for user component
const CompanyAdminComponent = lazy(() =>
  import("./components/manageUsers/CompanyAdmin")
);
const AddCompanyAdmin = lazy(() =>
  import("./components/manageUsers/AddCompanyAdmin")
);
const UpdateCompanyAdmin = lazy(() =>
  import("./components/manageUsers/UpdateCompanyAdmin")
);
//for forgot password component
const ForgotPasswordComponent = lazy(() =>
  import("./components/forgotpassword/ForgotPassword")
);
const EmailValidationComponent = lazy(() =>
  import("./components/forgotpassword/EmailValidation")
);
const OtpValidationComponent = lazy(() =>
  import("./components/forgotpassword/OtpValidation")
);
//for department component
const DeptDetailsComponent = lazy(() =>
  import("./components/managerDept/DeptDetails")
);
const AddDeptComponent = lazy(() => import("./components/managerDept/AddDept"));
const AddDocumentComponent = lazy(() =>
  import("./components/managerDept/AddDeptDocument")
);
const UpdateDocumentComponent = lazy(() =>
  import("./components/managerDept/UpdateDocument")
);
const UpdateDeptComponent = lazy(() =>
  import("./components/managerDept/UpdateDept")
);
//for company component
const ManageCompanyDetails = lazy(() =>
  import("./components/manageCompany/ManageComapany")
);
const AddCompanyDocumentDetails = lazy(() =>
  import("./components/manageCompany/AddCompDocument")
);
const UpdateCompanyDocumentDetails = lazy(() =>
  import("./components/manageCompany/UpdateCompDocument")
);
const AddCompanyDetails = lazy(() =>
  import("./components/manageCompany/AddCompany")
);
const UpdateCompanyDetails = lazy(() =>
  import("./components/manageCompany/UpdateCompany")
);
//for enquiry component
const AddEnquiryComponent = lazy(() => import("./components/manageEnquiry/AddEnquiry"));
const EnquiryDetailsComponent = lazy(() =>
  import("./components/manageEnquiry/EnquiryDetails")
);
const EditEnquiryDetailsComponent = lazy(() =>
  import("./components/manageEnquiry/EditEnquiryDocument")
);
const ViewEnquiryDocument = lazy(() =>
  import("./components/manageEnquiry/ViewEnquiryDocument")
);
const AddActivityComponent = lazy(() =>
  import("./components/manageEnquiry/AddActivity")
);
const EditActivityComponent = lazy(() =>
  import("./components/manageEnquiry/UpdateActivity")
);
//for client component
const ClientDetailsComponent = lazy(() =>
  import("./components/manageClients/ClientDetails")
);
const AddClientDetailsComponent = lazy(() =>
  import("./components/manageClients/AddClientDetail")
);
const EditclientDetailsComponent = lazy(() =>
  import("./components/manageClients/EditClientDetails")
);
//company setup component
const ManageCompanyRolesComponent = lazy(() =>
  import("./components/setups/roleSetups/ManageCompanyRoles")
);
const AddRolesSetupComponent = lazy(() =>
  import("./components/setups/roleSetups/AddRolesSetup")
);
const UpdateRoleSetupComponent = lazy(() =>
  import("./components/setups/roleSetups/UpdateRoleSetup")
);
const UpdateDropDownComponet = lazy(() => import("./components/setups/roleSetups/UpdateDropDown"));

const ManageDropDownSetupsComponent = lazy(() =>
  import("./components/setups/dropdownSetups/ManageDropDownSetups")
);
const AddDropDownSetupComponent = lazy(() =>
  import("./components/setups/dropdownSetups/AddDropDownSetup")
);
//for report component
const ReportDetailsComponent = lazy(() =>
  import("./components/manageReports/ReportDetails")
);
const SubscriptionComponent = lazy(() =>
  import("./components/manageSubscription/Subscription")
);
function App() {
  // const { role } = UseAuth();
  const location = useLocation();

  const isLoginPage = [
    "/",
    "/features",
    "/pricing",
    "/contact-us",
    "/faq",
    "/app-gallery",
    "/about",
    "/forgotPassword",
    "/register",
    "/login",
    "/signin",
    "/privacy",
    "/terms-and-conditions",
    "/yearly-subscription",
    "/payment/:payid",
    "*",
  ].includes(location.pathname);

  const homeHeader = ["/", "/features", "/pricing", "/contact-us", "/faq", "/app-gallery", "/about", "/privacy", "/terms-and-conditions"].includes(
    location.pathname
  );
  const isPaymentPage = location.pathname.startsWith("/payment/");
  const validPaths = [
    "/",
    "/signin",
    "/login",
    "/register",
    "/features",
    "/pricing",
    "/contact-us",
    "/faq",
    "/app-gallery",
    "/about",
    "/payment/:payid",
    "/forgotPassword",
    "/privacy",
    "/terms-and-conditions",
    "/yearly-subscription",
    "/myLeads",
    "/myLeads/CompanyAdmin",
    "/myLeads/AddCompanyAdmin",
    "/myLeads/UpdateCompanyAdmin/:cid",
    "/myLeads/DeptDetails",
    "/myLeads/AddDept",
    "/myLeads/AddDeptDocument",
    "/myLeads/UpdateDocument/:docid",
    "/myLeads/UpdateDept/:deptid",
    "/myLeads/ManageComapany",
    "/myLeads/AddCompDocument",
    "/myLeads/UpdateCompDocument/:docid",
    "/myLeads/AddCompany",
    "/myLeads/UpdateCompany/:cid",
    "/myLeads/AddClientDetail",
    "/myLeads/ClientDetails",
    "/myLeads/EditClientDetails/:cid",
    "/myLeads/roles-setup",
    "/myLeads/add-roles-setup",
    "/myLeads/update-roles-setup/:rsid",
    "/myLeads/UpdateDropDown/:rsid",
    "/myLeads/dropdown-setup",
    "/myLeads/add-roles-setup",
    "/myLeads/EnquiryDetails",
    "/myLeads/AddEnquiry",
    "/myLeads/ViewEnquiryDocument",
    "/myLeads/EditEnquiryDocument/:eid",
    "/myLeads/AddActivity",
    "/myLeads/UpdateActivity/:aid",
    "/myLeads/ReportDetails",
    "/myLeads/subscription/:sid"
  ];

  const isNotFoundPage = !validPaths.some(path => {
    const regex = new RegExp(`^${path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
    return regex.test(location.pathname);
  });
  return (
    <>
      <div className="app">
        {!isLoginPage && !isPaymentPage && !isNotFoundPage && <SideBarComponent />}
        <main className="content">
          {!isLoginPage && !isPaymentPage && !isNotFoundPage && <TopBarComponent />}
          {homeHeader && !isPaymentPage && <HeaderComponent />}
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <HomeComponent />
                  </Suspense>
                }
              />
              <Route
                path="signin"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CoreDashboardComponent />
                  </Suspense>
                }
              />
              <Route
                path="login"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <LoginComponent />
                  </Suspense>
                }
              />
              <Route
                path="register"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <RegisterComponent />
                  </Suspense>
                }
              />
              <Route
                path="features"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <FeaturesComponent />
                  </Suspense>
                }
              />
              <Route
                path="pricing"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <PricingComponent />
                  </Suspense>
                }
              />
              <Route
                path="contact-us"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ContactComponent />
                  </Suspense>
                }
              />
              <Route
                path="faq"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <FaqComponent />
                  </Suspense>
                }
              />
              <Route
                path="app-gallery"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AppGalleryComponent />
                  </Suspense>
                }
              />
              <Route
                path="about"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AboutComponent />
                  </Suspense>
                }
              />
              <Route
                path="payment/:payid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <PaymentSuccessMessageComponent />
                  </Suspense>
                }
              />
              <Route
                path="forgotPassword"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ForgotPasswordComponent />
                  </Suspense>
                }
              />
              <Route
                path="mail-validation"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EmailValidationComponent />
                  </Suspense>
                }
              />
              <Route
                path="otp-validation"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <OtpValidationComponent />
                  </Suspense>
                }
              />
              <Route
                path="privacy"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <PrivacyComponent />
                  </Suspense>
                }
              />
              <Route
                path="terms-and-conditions"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <TncComponet />
                  </Suspense>
                }
              />
              <Route
                path="yearly-subscription"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AuthGuard>
                      <SubscriptionPageComponent />
                    </AuthGuard>
                  </Suspense>
                }
              />

              <Route path="/myLeads">
                <Route
                  index
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <DashBoardComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="CompanyAdmin"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <CompanyAdminComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddCompanyAdmin"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddCompanyAdmin />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateCompanyAdmin/:cid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateCompanyAdmin />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="DeptDetails"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <DeptDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddDept"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddDeptComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateDept/:deptid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateDeptComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddDeptDocument"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddDocumentComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateDocument/:docid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateDocumentComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="ManageComapany"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ManageCompanyDetails />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddCompDocument"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddCompanyDocumentDetails />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateCompDocument/:docid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateCompanyDocumentDetails />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddCompany"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddCompanyDetails />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateCompany/:cid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateCompanyDetails />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="ClientDetails"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ClientDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddClientDetail"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddClientDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="EditClientDetails/:cid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <EditclientDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="roles-setup"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ManageCompanyRolesComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="add-roles-setup"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddRolesSetupComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="update-roles-setup/:rsid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateRoleSetupComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateDropDown/:rsid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <UpdateDropDownComponet />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="dropdown-setup"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ManageDropDownSetupsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="add-roles-setup"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddDropDownSetupComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />

                <Route
                  path="EnquiryDetails"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <EnquiryDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddEnquiry"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddEnquiryComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="ViewEnquiryDocument"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ViewEnquiryDocument />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="EditEnquiryDocument/:eid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <EditEnquiryDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="AddActivity"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <AddActivityComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="UpdateActivity/:aid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <EditActivityComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="ReportDetails"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <ReportDetailsComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
                <Route
                  path="subscription/:sid"
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <AuthGuard>
                        <SubscriptionComponent />
                      </AuthGuard>
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          {/* {homeHeader && <ArrowUpward onClick={() => window.scroll(0, 0)} style={{ position: "fixed", top: "85%", left: "50%", color: "white", backgroundColor: "#0072FA", borderRadius: "50%", fontSize: "3em" }} />} */}
          {homeHeader && !isPaymentPage && <FooterComponent />}
        </main>
      </div>
    </>
  );
}

export default App;
