import { Typography, Grid, Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const FooterComponent = () => {
    return (
        <Container maxWidth="100%" sx={{ py: 5, backgroundColor: 'white' }}>
            <Grid container sx={{ pb: 5, borderBottom: '1px solid #E0E0E0', display: "flex", alignItems: "start" }}>
                <Grid item xs={6} sm={6} md={3} xl={2.5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: { xs: "start" }, p: 0.5 }}>
                    <Typography variant="h6" gutterBottom>My Leads</Typography>
                    <Typography variant="body2">
                        No 51/A1 Nihal Arcade 2nd Floor Manuvana Hosahalli Extension Sri Krishnadevaraya Rd Opp D-Mart
                        Vijayanagar
                        Bengaluru-560040
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}><strong>Phone:</strong>+91 966 374 2371</Typography>
                    <Typography variant="body2"><strong>Email:</strong>info@ganakalabs.com</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} xl={2.5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: { xs: "start" }, p: 0.5 }}>
                    <Typography variant="h6" gutterBottom>Useful Links</Typography>
                    <Link to="/">Home</Link>
                    <Link to="/about">About us</Link>
                    <Link to="/features">Features</Link>
                    <Link to="/app-gallery">App Gallery</Link>
                    <Link to="/faq">F.A.Q</Link>
                    <Link to="/contact-us">Contact Us</Link>
                    <Link to="/terms-and-conditions">Terms of services</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                </Grid>

                <Grid item xs={6} sm={6} md={3} xl={2.5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: { xs: "start" }, p: 0.5 }}>
                    <Typography variant="h6" gutterBottom>Our Services</Typography>
                    <Typography variant="body2">Website Developing</Typography>
                    <Typography variant="body2">WB Mobile App</Typography>
                    <Typography variant="body2">Cloud Hosting</Typography>
                    <Typography variant="body2">IOT & GenAI</Typography>
                    <Typography variant="body2">Cyber Security</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3} xl={2.5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: { xs: "start" }, p: 0.5 }}>
                    <Typography variant="h6" gutterBottom> Follow Us</Typography>
                    <Typography variant="body2">
                        This one-liner encourages users to stay connected.
                        Does this fit your footer?
                    </Typography>
                </Grid>
            </Grid>

            <Typography variant="body2" align="center" sx={{ mt: 5 }}>
                &copy; Copyright <strong>My Leads</strong> All Rights Reserved<br />
                Designed by <Link href="https://ganakalabs.com">Ganaka Labs</Link>
            </Typography>
        </Container>
    )
}

export default FooterComponent