import { Close, Menu } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Links = ({ label, link, sethide }) => {
    const location = useLocation();
    const isActive = location.pathname === link;

    return (
        <Link to={link}
            style={{
                textDecoration: "none",
                color: "black",
                padding: "0.5em 0"
            }}
            onClick={() => sethide(false)}>

            <Typography variant='button'
                sx={{
                    fontSize: { xs: "0.6em", sm: "0.62em", md: "1em", xl: "1em" },
                    borderTop: isActive ? "2px blue solid" : "none",
                    "&&:hover": {
                        borderTop: isActive ? "2px blue solid" : "2px blue solid",
                    }
                }}
            >
                {label}
            </Typography>
        </Link>
    )
}

const HeaderComponent = () => {
    const [hide, setHide] = useState(false)
    const menuBarHandle = () => {
        setHide(!hide)
    }
    return (
        <Grid container sx={{ position: 'fixed', top: 0, zIndex: 999 }}>
            <Grid item xs={12} sx={{ height: "10vh", px: { xs: 1, sm: 2 }, display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "white" }}>
                <Box>
                    <Link to={'/'}>
                        <img src="/my_lead_logo.png" alt="my_lead_logo" style={{ width: 150, height: 50, margin: "2em", paddingTop: ".5em" }} />
                    </Link>
                </Box>
                {/* <Box sx={{ width: "60%", display: { xs: "none", sm: "flex" },gap:2,alignContent:{xs:"flex-end",sm:"flex-end"},p:{xs:"0em",sm:"2rem"}}}> */}
                {/* </Box> */}
                <Box sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "space-around", alignItems: "center", gap: { xs: "1em", sm: "0.7em", md: "1em", xl: "3em" }, marginRight: "30px" }}>
                    <Links label={'Home'} link={'/'} sethide={setHide} />
                    <Links label={'About'} link={'/about'} sethide={setHide} />
                    <Links label={'Features'} link={'/features'} sethide={setHide} />
                    <Links label={'App Gallery'} link={'/app-gallery'} sethide={setHide} />
                    <Links label={'F.A.Q'} link={'/faq'} sethide={setHide} />
                    <Links label={'Contact'} link={'/contact-us'} sethide={setHide} />
                    <Links label={'Sign in'} link={'/signin'} sethide={setHide} />
                    <Button variant='contained' sx={{ borderRadius: "2em", backgroundColor: "#5777BA" }}>


                        <Link to={'/register'} style={{ textDecoration: "none", color: "white" }}>
                            Register
                        </Link>
                    </Button>
                </Box>
                <Box sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "flex-end", alignItems: "center" }}>
                    {!hide && <Menu style={{ fontSize: "2em", color: "#23A6F0" }} onClick={menuBarHandle} />}
                    {hide && <Close style={{ fontSize: "2em", color: "#ff3d33" }} onClick={menuBarHandle} />}
                </Box>
            </Grid>
            {hide && (
                <Grid item xs={12} sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "flex-end" }}>
                    <Box sx={{ width: "60%", display: { xs: "flex", sm: "none" }, flexDirection: "column", justifyContent: "space-around", alignItems: "center", backgroundColor: "white", boxShadow: "0 5px 5px black", py: 1 }}>
                        <Links label={'Home'} link={'/'} sethide={setHide} />
                        {/* <Links label={'Features'} link={'/features'} sethide={setHide} />
                        <Links label={'Pricing'} link={'/pricing'} sethide={setHide} />
                        <Links label={'Contact Us'} link={'/contact-us'} sethide={setHide} />
                        <Links label={'Sign in'} link={'/signin'} sethide={setHide} /> */}
                        <Links label={'About'} link={'/about'} sethide={setHide} />
                        <Links label={'Features'} link={'/features'} sethide={setHide} />
                        <Links label={'App Gallery'} link={'/app-gallery'} sethide={setHide} />
                        <Links label={'F.A.Q'} link={'/faq'} sethide={setHide} />
                        <Links label={'Contact'} link={'/contact-us'} sethide={setHide} />
                        <Links label={'Sign in'} link={'/signin'} sethide={setHide} />
                        <Button variant='contained'>

                            <Link to={'/register'} style={{ textDecoration: "none", color: "white" }} >Register</Link>
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default HeaderComponent;
