import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import { decryptFuc } from "../../utils/decrpt";
const getToken = () => localStorage.getItem("my_leads");
// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const getAllEnquirydata = createAsyncThunk(
  "enquiry/getAllEnquirydata",
  async () => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(`my_Leads/enquiry`, getAuthHeaders());
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const getSingleEnquirydata = createAsyncThunk(
  "enquiry/getSingleEnquirydata",
  async (id) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `/my_Leads/enquiry/${id}`,
        getAuthHeaders()
      );
      const responseData = await decryptFuc(response.data.data);
      // console.log("response.data", responseData);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const UpdateEnquirydata = createAsyncThunk(
  "enquiry/UpdateEnquirydata",
  async (data) => {
    try {
      // console.log("authtnk==>", getAuthHeaders())
      const response = await BaseUrl.patch(
        `/my_Leads/enquiry/`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const getLeadTypedropdown = createAsyncThunk(
  "enquiry/getLeadTypedropdown",
  async (id) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `/my_Leads/company-setup-dropdown/field-value/${id}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const enquirySlice = createSlice({
  name: "enquiry",
  initialState: {
    enquiryData: [],
    singleEnquiryData: {},
    leaddropdown:[],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEnquirydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEnquirydata.fulfilled, (state, action) => {
        state.loading = false;
        state.enquiryData = action.payload;
      })
      .addCase(getAllEnquirydata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleEnquirydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleEnquirydata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleEnquiryData = action.payload;
      })
      .addCase(getSingleEnquirydata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      builder
      .addCase(getLeadTypedropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeadTypedropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.leaddropdown = action.payload;
      })
      .addCase(getLeadTypedropdown.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const getEnquiryData = (state) => state.enquiry.enquiryData;
export const getSinglenquiryData = (state) => state.enquiry.singleEnquiryData;
export const getLeadDropDown = (state) => state.enquiry.leaddropdown;
export const loginerror = (state) => state.enquiry.loading;
export default enquirySlice.reducer;