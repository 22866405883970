import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import { decryptFuc } from "../../utils/decrpt";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("my_leads");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

//dept SetUp list
export const getCalenderAPI = createAsyncThunk(
    "report/getCalenderAPI",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/enquiry/calendar/`,
                getAuthHeaders()
            );
            // console.log("getCalenderAPI==>", response.data.data)
            const responseData = await decryptFuc(response.data.data);
            return responseData;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getEnquiryStatusCardAPI = createAsyncThunk(
    "report/getEnquiryStatusCardAPI",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/enquiry/status-count/`,
                getAuthHeaders()
            );
            // console.log("getCalenderAPI==>", response.data.data)
            const responseData = await decryptFuc(response.data.data);
            return responseData;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getCompanyConnectionAPI = createAsyncThunk(
    "report/getCompanyConnectionAPI",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/clients/unique-data`,
                getAuthHeaders()
            );
            // console.log("getCalenderAPI==>", response.data.data)
            const responseData = await decryptFuc(response.data.data);
            return responseData;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getBarChartAPI = createAsyncThunk(
    "barChart/getBarChartAPI",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/enquiry/bar-chart`,
                getAuthHeaders()
            );
            // console.log("getBarChartAPI==>", response.data.data)
            const responseData = await decryptFuc(response.data.data);
            return responseData;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const dashBoardSlice = createSlice({
    name: "dashboard",
    initialState: {
        calnderData: [],
        enquiryCardData: [],
        companyConnectionData: [],
        barChartData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCalenderAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCalenderAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.calnderData = action.payload;
            })
            .addCase(getCalenderAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getEnquiryStatusCardAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEnquiryStatusCardAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.enquiryCardData = action.payload;
            })
            .addCase(getEnquiryStatusCardAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getCompanyConnectionAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyConnectionAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.companyConnectionData = action.payload;
            })
            .addCase(getCompanyConnectionAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getBarChartAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBarChartAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.barChartData = action.payload;
            })
            .addCase(getBarChartAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});
export const calnderDatas = (state) => state.dashboard.calnderData;
export const enquiryCardDatas = (state) => state.dashboard.enquiryCardData;
export const companyConnectionDatas = (state) => state.dashboard.companyConnectionData;
export const barChartDataDatas = (state) => state.dashboard.barChartData;
export const dashBoardError = (state) => state.dashboard.loading;

// EXPORTS
export default dashBoardSlice.reducer;
