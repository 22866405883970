import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import Swal from "sweetalert2";
import { decryptFuc } from "../../utils/decrpt";

const getToken = () => localStorage.getItem("my_leads");

// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const getCompanyDropdata = createAsyncThunk(
  "dept/getCompanyDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(
        `/my_Leads/company/companyDropDown`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data.data)
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const getDeptDocdata = createAsyncThunk(
  "dept/getDeptDocdata",
  async (id) => {
    try {
      const response = await BaseUrl.get(
        `my_Leads/departmentDocs/allDocs/${id}`,
        getAuthHeaders()
      );
      const responseData = await decryptFuc(response.data.data);
      // console.log("response.data", responseData)
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const createDeptDocdata = createAsyncThunk(
  "dept/createDeptDocdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(
        `my_Leads/departmentDocs`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const getAllDepdata = createAsyncThunk(
  "dept/getAllDeptdata",
  async () => {
    try {
      const response = await BaseUrl.get(
        `/my_Leads/department/`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data.data)
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const createDeptdata = createAsyncThunk(
  "dept/createDeptdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(
        `/my_Leads/department`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const getSingleDeptdata = createAsyncThunk(
  "dept/getSingleDeptdata",
  async (id) => {
    try {
      const response = await BaseUrl.get(
        `/my_Leads/department/${id}`,
        getAuthHeaders()
      );
      const responseData = await decryptFuc(response.data.data);
      // console.log("responseData===>", responseData);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const updateDeptdata = createAsyncThunk(
  "dept/updateDeptdata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(
        `/my_Leads/department`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const getSingleDeptDoc = createAsyncThunk(
  "dept/getSingleDeptDoc",
  async (id) => {
    try {
      // console.log("id====>", id);
      const response = await BaseUrl.get(
        `/my_Leads/departmentDocs/${id}`,
        getAuthHeaders()
      );
      // console.log("getSingleDeptDoc===>", response.data)
      return response.data.data[0];
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const updateDeptDocdata = createAsyncThunk(
  "dept/updateDeptDocdata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(
        "/my_Leads/departmentDocs/",
        data,
        getAuthHeaders()
      );
      // console.log("company docs===>", response.data)
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const deleteDepartmentDocdata = createAsyncThunk(
  "companys/deleteDepartmentDocdata",
  async (id) => {
    // console.log("getAuthHeaders()===>", getAuthHeaders())
    try {
      const response = await BaseUrl.patch(`my_Leads/departmentDocs/department-docs`, { id }, getAuthHeaders());
      // console.log("company docs===>", response.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      Swal.fire({
        icon: "error",
        title: "Document",
        text: `${error?.response?.data?.message}`,
        showConfirmButton: false,
        timer: 3000
      })
      throw error; // Ensure the error is propagated
    }
  }
);
export const createDeptSlice = createSlice({
  name: "createDept",
  initialState: {
    deptData: [],
    companyDropdata: [],
    deptDocdata: [],
    unicDeptdata: {},
    unicDeptdocdata: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDepdata.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllDepdata.fulfilled, (state, action) => {
      state.loading = false;
      state.deptData = action.payload;
    });
    builder.addCase(getAllDepdata.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getCompanyDropdata.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompanyDropdata.fulfilled, (state, action) => {
      state.loading = false;
      state.companyDropdata = action.payload;
    });
    builder.addCase(getCompanyDropdata.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getSingleDeptdata.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleDeptdata.fulfilled, (state, action) => {
      state.loading = false;
      state.unicDeptdata = action.payload;
    });
    builder.addCase(getSingleDeptdata.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getDeptDocdata.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDeptDocdata.fulfilled, (state, action) => {
      state.loading = false;
      state.deptDocdata = action.payload;
    });
    builder.addCase(getDeptDocdata.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getSingleDeptDoc.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleDeptDoc.fulfilled, (state, action) => {
      state.loading = false;
      state.unicDeptdocdata = action.payload;
    });
    builder.addCase(getSingleDeptDoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const getDeptdata = (state) => state.createDept.deptData;
export const getDropDowndata = (state) => state.createDept.companyDropdata;
export const getDepartmentDocdata = (state) => state.createDept.deptDocdata;
export const getUnicDeptdata = (state) => state.createDept.unicDeptdata;
export const getUnicDeptDocdata = (state) => state.createDept.unicDeptdocdata;
export const loginerror = (state) => state.createDept.loading;

export default createDeptSlice.reducer;