import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import { decryptFuc } from "../../utils/decrpt";

const getToken = () => localStorage.getItem("my_leads");
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

export const getSubscriptionApi = createAsyncThunk(
    "subscriptionInvoice/getSubscriptionApi",
    async (id) => {
        try {
            // console.log("authtnk", getAuthHeaders())
            const response = await BaseUrl.get(`/my_Leads/customersub/subscription/${id}`, getAuthHeaders());
            console.log("response.data", response.data.data);
            const responseData = await decryptFuc(response.data.data);
            return responseData;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const invoicePaymentApi = createAsyncThunk(
    "InvoicePaymentInvoice/invoicePaymentApi",
    async (data) => {
        try {
            // console.log("authtnk", getAuthHeaders())
            const response = await BaseUrl.patch(`/my_Leads/invoice/status-update`, data, getAuthHeaders());
            console.log("response.data", response.data.data);
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const subscriptionInvoiceSlice = createSlice({
    name: "invoicesubscription",
    initialState: {
        subscriptionData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSubscriptionApi.fulfilled, (state, action) => {
                state.loading = false;
                state.subscriptionData = action.payload;
            })
            .addCase(getSubscriptionApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});
export const getSubscriptionInvoiceData = (state) => state.invoicesubscription.subscriptionData;
export const invoiceSubscriptionLoader = (state) => state.invoicesubscription.loading;
export default subscriptionInvoiceSlice.reducer;
