import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import { decryptFuc } from "../../utils/decrpt";
const getToken = () => localStorage.getItem("my_leads");
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const getAllActivitydata = createAsyncThunk(
  "activity/getAllActivitydata",
  async (id) => {
    try {
      // console.log("id", id)
      const response = await BaseUrl.get(
        `my_Leads/activity-table/enquiryID/${id}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const createActivitydata = createAsyncThunk(
  "activity/createActivitydata",
  async (data) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.post(
        `/my_Leads/activity-table`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const getSingleActivitydata = createAsyncThunk(
  "activity/getSingleActivitydata",
  async (id) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `/my_Leads/activity-table/${id}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      // alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const UpdateActivitydata = createAsyncThunk(
  "activity/UpdateActivitydata",
  async (data) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.patch(
        `/my_Leads/activity-table`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      const responseData = await decryptFuc(response.data.data);
      return responseData;
    } catch (error) {
      alert(`${error.response.data.message}`);
      throw error; // Ensure the error is propagated
    }
  }
);
export const activitySlice = createSlice({
  name: "activity",
  initialState: {
    activityData: [],
    singleActivityData: {},
    permission: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivitydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActivitydata.fulfilled, (state, action) => {
        state.loading = false;
        state.activityData = action.payload;
      })
      .addCase(getAllActivitydata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleActivitydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleActivitydata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleActivityData = action.payload;
      })
      .addCase(getSingleActivitydata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const getActivityData = (state) => state.activity.activityData;
export const getUniqueActivityData = (state) =>
  state.activity.singleActivityData;
export default activitySlice.reducer;
